import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SectionHeader } from '../../common';
import Container from '../../container';
import { ShadedStarIcon } from '../../Icons';

import google from "../../../assets/logos/google.png";
import amazon from "../../../assets/logos/amazon.png";
import ycombinator from "../../../assets/logos/ycombinator.png";
import cmu from "../../../assets/logos/cmu.png";
import facebook from "../../../assets/logos/facebook.png";

const BuiltByContent = styled(Container)`
  margin-top: 50px;
  text-align: center;
`;

const LogoHolder = styled.div`
  @media only screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 100px;
    align-items: center;
    justify-items: center;
  }

  @media only screen and (min-width: 700px) {
    padding: 30px 50px 0 50px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 75px;
    align-items: center;
    justify-items: center;
  }
`;

const LogoImage = styled.img`
  max-height: 80px;
  max-width: 170px;
`;

const BuiltBy = ({ data }) => {
  const { title } = data.builtBy;

  const logos = [
    google,
    amazon,
    ycombinator,
    cmu,
    facebook
  ];

  return (
    <section id="builtBy">
      <BuiltByContent>
        <ShadedStarIcon />
        <SectionHeader>{title}</SectionHeader>
        <LogoHolder>
          {
            logos.map(l =>
              <LogoImage key={l} src={l} />)
          }
        </LogoHolder>
      </BuiltByContent>
    </section>
  );
};

BuiltBy.propTypes = {
  data: PropTypes.object,
};

export default BuiltBy;
