import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../../container';
import { CenteredMainTitle } from '../../intro/common';
import { SectionDescription } from '../../common';

const IntroContent = styled(Container)`
  margin-top: 50px;
`;

const Summary = styled.div`
  margin: 20px auto 0 auto;

  @media only screen and (min-width: 700px) {
    width: 400px;
  }
`;

const Intro = ({ data }) => {
  const { title } = data.intro;
  const { answer } = data.summary;

  return (
    <section id="intro">
      <IntroContent>
        <CenteredMainTitle>
          {title}
        </CenteredMainTitle>
        <Summary>
          <SectionDescription>
            {answer}
          </SectionDescription>
        </Summary>
      </IntroContent>
    </section>
  );
};

Intro.propTypes = {
  data: PropTypes.object,
};

export default Intro;
