import React from "react";

import data from "../data/company.yaml";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/pages/company/intro";
import Stats from "../components/pages/company/stats";
import BuiltBy from "../components/pages/company/builtBy";
import Investors from "../components/pages/company/investors";
import Quote from "../components/pages/company/quote";
import Careers from "../components/pages/company/careers";

const CompanyPage = () => (
  <Layout>
    <SEO
      title="Company"
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />

    <Intro data={data} />
    <Stats data={data} />
    <BuiltBy data={data} />
    <Investors data={data} />
    <Quote data={data} />
    <Careers />
  </Layout>
);

export default CompanyPage;
