import React from "react";
import styled from "styled-components";

import { SectionHeader, SectionDescription } from "../../common";
import Container from "../../container";
import { baseWhiteButton } from "../../button";
import { Link } from "gatsby";

export const CareersContent = styled(Container)`
  margin-top: 200px;
  text-align: center;
`;

const SeeCareersButton = styled(Link)`
  ${baseWhiteButton}
`;

export const Careers = () => {
  return (
    <CareersContent>
      <SectionHeader>Join Us</SectionHeader>
      <SectionDescription>
        Percept AI is growing fast. Grow with us.
      </SectionDescription>
      <SeeCareersButton to="/careers">See Careers</SeeCareersButton>
    </CareersContent>
  );
};

export default Careers;
