import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Container from "../../container";
import { QuoteIcon } from "../../Icons";

import EricAvatar from "../../../assets/avatars/Eric.png";

const QuoteIconHolder = styled.div`
  margin: 20px 10px 30px 10px;
`;

const QuoteContent = styled(Container)`
  margin-top: 100px;

  @media only screen and (max-width: 700px) {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 700px) {
    display: flex;
    justify-content: center;
  }
`;

const AvatarContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const AvatarTitle = styled.div`
  font-size: 20px;
  margin: 10px 0 5px 0;
`;

const AvatarRow = styled.div`
  color: #4f5a79;
  margin: 2px 0;
`;

const AvatarImage = styled.img`
  max-width: 125px;
  max-height: 125px;
  margin: 10px;
`;

const QuoteText = styled.div`
  font-style: italic;
  font-size: 20px;
  line-height: 40px;
  color: #4f5a79;

  @media only screen and (min-width: 700px) {
    margin-left: 40px;
    max-width: 600px;
  }
`;

const Quote = ({ data }) => {
  return (
    <section id="quote">
      <QuoteContent>
        <QuoteIconHolder>
          <QuoteIcon />
        </QuoteIconHolder>
        <QuoteText>{data.quote.text}</QuoteText>
        <AvatarContainer>
          <AvatarImage src={EricAvatar} />
          <AvatarTitle>{data.quote.name}</AvatarTitle>
          <AvatarRow>{data.quote.title}</AvatarRow>
          <AvatarRow>{data.quote.company}</AvatarRow>
        </AvatarContainer>
      </QuoteContent>
    </section>
  );
};

Quote.propTypes = {
  data: PropTypes.object,
};

export default Quote;
