import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Container from '../../container';
import { ShadedKeyStatsIcon, ChatDotsIcon, PeopleIcon, LightbulbIcon, HourglassIcon } from '../../Icons';

import StatsMobileBackground from '../../../assets/company/StatsMobileBackground.svg';
import CompanyBackground from '../../../assets/company/CompanyBackground.svg';

import SupportLady from './inline/SupportLady.svg';
import QuestioningMan from './inline/QuestioningMan.svg';

const FloatingSupportLady = styled.div`
  position: absolute;
  top: -200px;
  left: 30px;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const FloatingQuestioningMan = styled.div`
  position: absolute;
  top: -425px;
  right: -200px;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

const StatsSection = styled.section`
  position: relative;
  margin-top: 50px;
  padding: 25px 0;
`;

const StatsBackground = styled.div`
  position: absolute;
  z-index: -1;

  @media only screen and (max-width: 700px) {
    top: -325px;
    left: 0px;
    width: 100%;
    height: 125%;
    background:
      url(${StatsMobileBackground})
      0 0 / cover
      no-repeat
      border-box;
  }

  @media only screen and (min-width: 700px) {
    top: -600px;
    left: 0px;
    width: 100%;
    height: 2000px;
    background:
      url(${CompanyBackground})
      center / 180% 180%
      no-repeat
      border-box;
  }

  @media only screen and (min-width: 1000px) {
    top: -500px;
    left: 0px;
    width: 100%;
    height: 1800px;
    background:
      url(${CompanyBackground})
      center / 110% 110%
      no-repeat
      border-box;
  }
`;

const StatsContent = styled(Container)`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
`;

const SectionHeader = styled.div`
  font-size: 45px;
  line-height: 58px;
  color: white;
  margin-top: 10px;
  margin-bottom: 50px;
`;

const StatsHolder = styled.div`
  color: #FFFFFF;
  padding: 0 30px;

  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 150px;
  }
`;

const SingleStatCount = styled.div`
  font-family: 'Gotham-Medium';
  text-align: center;
  line-height: 43px;
  margin: 15px 0;
  font-size: 48px;
`;

const SingleStatTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 29px;
  text-transform: uppercase;
`;

const SingleStatSubtitle = styled.div`
  opacity: 0.7;
  font-size: 17px;
  text-align: center;
  line-height: 24px;
  margin-bottom: 150px;

  @media only screen and (min-width: 700px) {
    padding: 0 50px;
  }
`;

const IconHolder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 5px 0;
  height: 100px;

  &:before {
    content: '';
    position: absolute;
    transform: rotate(-315deg);
    background-image: linear-gradient(-130deg, #A5A6FF 0%, rgba(165,166,255,0.00) 100%);
    box-shadow: 40px 20px 120px 0 rgba(10,15,39,0.28);
    border-radius: 22px;
    background: #FFFFFF40;
    width: 70px;
    height: 70px;
  }
`;

const SingleStat = ({ icon, title, subtitle, count }) => {
  return (
    <div>
      <IconHolder>{icon}</IconHolder>
      <SingleStatCount>{count}</SingleStatCount>
      <SingleStatTitle>{title}</SingleStatTitle>
      <SingleStatSubtitle>{subtitle}</SingleStatSubtitle>
    </div>
  );
};

SingleStat.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  count: PropTypes.string,
  subtitle: PropTypes.string,
};

const Stats = ({ data }) => {
  const { title, questions, days, responseTime, improvement } = data.keyStats;

  const stats = [{
    icon: <ChatDotsIcon />,
    ...questions,
  }, {
    icon: <PeopleIcon />,
    ...days,
  }, {
    icon: <LightbulbIcon />,
    ...improvement,
  }, {
    icon: <HourglassIcon />,
    ...responseTime,
  }];

  return (
    <StatsSection id="stats">
      <StatsContent>
        <ShadedKeyStatsIcon />
        <SectionHeader>{title}</SectionHeader>
        <StatsHolder>
          {
            stats.map((s, i) =>
              <SingleStat
                key={i}
                icon={s.icon}
                title={s.title}
                subtitle={s.subtitle}
                count={s.count}
              />
            )
          }
        </StatsHolder>
      </StatsContent>
      <FloatingSupportLady>
        <SupportLady />
      </FloatingSupportLady>
      <FloatingQuestioningMan>
        <QuestioningMan />
      </FloatingQuestioningMan>
      <StatsBackground />
    </StatsSection>
  );
};

Stats.propTypes = {
  data: PropTypes.object,
};

export default Stats;
