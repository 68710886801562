import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SectionHeader, SectionDescription } from '../../common';
import Container from '../../container';
import { ShadedColoredGroupIcon } from '../../Icons';

import amino from '../../../assets/investors/amino.png';
import builders from '../../../assets/investors/builders.png';
import cherubic from '../../../assets/investors/cherubic.png';
import hike from '../../../assets/investors/hike.png';
import incubate from '../../../assets/investors/incubate.png';
import k2vc from '../../../assets/investors/k2vc.png';
import tribe from '../../../assets/investors/tribe.png';
import tsingyuan from '../../../assets/investors/tsingyuan.png';
import wisemont from '../../../assets/investors/wisemont.png';
import ycombinator from '../../../assets/investors/ycombinator.png';

const InvestorsContent = styled(Container)`
  margin-top: 140px;
  padding-bottom: 30px;
  text-align: center;
`;

const InvestorsText = styled.div`
  flex: 1;

  @media only screen and (min-width: 700px) {
    margin: 0 auto;
    max-width: 900px;
  }
`;

const LogoHolder = styled.div`
  @media only screen and (max-width: 500px) {
    padding: 20px 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
  }

  @media only screen and (min-width: 500px) {
    padding: 20px;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 75px;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    align-items: center;
    justify-items: center;
  }

  @media only screen and (min-width: 1000px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const LogoImage = styled.img`
  max-height: 80px;
  max-width: 170px;

  @media only screen and (max-width: 700px) {
    justify-self: center;
    align-self: center;
  }
`;

const Investors = ({ data }) => {
  const { title, subtitle } = data.investors;

  const logos = [
    ycombinator,
    builders,
    cherubic,
    amino,
    tsingyuan,
    tribe,
    incubate,
    hike,
    wisemont,
    k2vc,
  ];

  return (
    <section id='investors'>
      <InvestorsContent>
        <InvestorsText>
          <ShadedColoredGroupIcon />
          <SectionHeader>{title}</SectionHeader>
          <SectionDescription>{subtitle}</SectionDescription>
        </InvestorsText>
        <LogoHolder>
          {logos.map(l => (
            <LogoImage key={l} src={l} />
          ))}
        </LogoHolder>
      </InvestorsContent>
    </section>
  );
};

Investors.propTypes = {
  data: PropTypes.object,
};

export default Investors;
